import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { useMutation, useLazyQuery, gql } from '@apollo/client';

import CartDishes from './CartDishes';
import PaymentOptionModal from './PaymentOptionModal';
import EmptyCart from 'components/EmptyCart';
import ErrorModal from 'components/ErrorModal';
import Title from 'components/Title';
import CartContext from 'contexts/CartContext';
import DefaultLayout from 'layouts/DefaultLayout';

type OrderMutationResponse = {
  orderFood: {
    id: string;
    referenceId: string;
    status: string;
    paymentLink: string;
  };
};

type OrderAsGuestMutationResponse = {
  orderFoodAsGuest: {
    id: string;
    referenceId: string;
    status: string;
  };
};

const ORDER_FOOD = gql`
  mutation orderFood(
    $vendorId: ID!
    $dishes: [OrderedDishInput]
    $paymentMode: CashFreePaymentModeEnumType
    $returnUrl: String
    $notes: String
  ) {
    orderFood(
      vendorId: $vendorId
      dishes: $dishes
      paymentMode: $paymentMode
      returnUrl: $returnUrl
      notes: $notes
    ) {
      id
      referenceId
      status
      paymentLink
    }
  }
`;

const ORDER_FOOD_AS_GUEST = gql`
  mutation orderFoodAsGuest(
    $vendorId: ID!
    $roomId: ID
    $spotId: ID
    $dishes: [OrderedDishInput]
    $notes: String
  ) {
    orderFoodAsGuest(
      vendorId: $vendorId
      roomId: $roomId
      spotId: $spotId
      dishes: $dishes
      notes: $notes
    ) {
      id
      referenceId
      status
    }
  }
`;

type PaymentModesQueryResponse = {
  vendor: {
    id: string;
    paymentModes: string[];
  };
  connectedRoom: {
    id: string;
  };
};

const GET_PAYMENT_MODES = gql`
  query getPaymentModes($vendorId: ID!) {
    vendor(id: $vendorId) {
      id
      paymentModes
    }
    connectedRoom(vendorId: $vendorId) {
      id
    }
  }
`;

const Cart = () => {
  const history = useHistory();
  const { cartState, cartDispatch } = React.useContext(CartContext);
  const [error, setError] = React.useState('');

  const [modalVisible, setModalVisible] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const isCartEmpty = cartState.dishesOnCart.length;

  const [getPaymentModes, { data }] = useLazyQuery<PaymentModesQueryResponse>(GET_PAYMENT_MODES, {
    onError(e) {
      setError('UNKNOWN');
    },
    fetchPolicy: 'network-only',
  });

  const [orderFood, { loading }] = useMutation<OrderMutationResponse>(ORDER_FOOD, {
    onCompleted(data) {
      const order = data.orderFood;

      if (order.status === 'PAID') {
        cartDispatch({
          type: 'CLEAR_CART',
          payload: null,
        });
        history.replace(`/orders/${order.id}?shouldRedirectToVendor=true`);
        return;
      }

      window.location.replace(order.paymentLink);
    },
    onError(e) {
      setError('UNKNOWN');
    },
  });

  const [orderFoodAsGuest, { loading: loadingAsGuest }] = useMutation<OrderAsGuestMutationResponse>(
    ORDER_FOOD_AS_GUEST,
    {
      onCompleted(data) {
        const order = data.orderFoodAsGuest;
        cartDispatch({
          type: 'CLEAR_CART',
          payload: null,
        });
        history.replace(`/orders/${order.id}?shouldRedirectToVendor=true`);
      },
      onError(e) {
        setError('UNKNOWN');
      },
    }
  );

  const goToVendors = () => {
    history.push('Home');
  };

  const goToPayment = (
    paymentMode: 'PAYMENT_GATEWAY' | 'PURSE' | 'BILL_TO_ROOM' | 'BILL_TO_SPOT'
  ) => {
    const vendorId = cartState.vendorOnCart.id;
    const dishesOnCart = cartState.dishesOnCart;
    const notesOnCart = cartState.notes;
    const scheduledTimeOnCart = cartState.scheduledTime;
    const dishes = dishesOnCart.map(({ id, quantity, combinations }) => {
      const combinationIds: any = combinations
        ? // @ts-ignore
          combinations.reduce((acc, curr) => {
            const optionId = curr.option ? curr.option.id : null;
            const addOnIds = curr.addOns ? curr.addOns.map((addOn) => addOn.id) : null;
            return [
              ...acc,
              {
                optionId,
                addOnIds,
              },
            ];
          }, [])
        : null;
      const payload = {
        dishId: id,
        quantity,
        combinations: combinationIds,
      };
      return payload;
    });

    if (paymentMode === 'BILL_TO_ROOM' || paymentMode === 'BILL_TO_SPOT') {
      orderFoodAsGuest({
        variables: {
          vendorId,
          dishes,
          notes: notesOnCart,
          paymentMode,
          roomId: data && data.connectedRoom ? data.connectedRoom.id : '',
          spotId: Cookies.get('spotId'),
        },
      });
      return;
    }

    orderFood({
      variables: {
        vendorId,
        dishes,
        paymentMode,
        notes: notesOnCart,
        deliveryScheduledAt: scheduledTimeOnCart,
        returnUrl: `/orders/{0}?shouldRedirectToVendor=true`,
      },
    });
  };

  const checkPaymentModes = () => {
    const vendorId = cartState.vendorOnCart.id;
    getPaymentModes({
      variables: {
        vendorId,
      },
    });
  };

  useEffect(() => {
    if (!data) return;

    const { vendor } = data;

    switch (true) {
      //@ts-ignore
      case vendor.paymentModes === ['PAYMENT_GATEWAY']:
        goToPayment('PAYMENT_GATEWAY'); // cashfree
        return;
      case vendor.paymentModes.length > 1:
        setModalVisible(true); // show payment selection screen
        return;
      default:
        goToPayment('PAYMENT_GATEWAY'); // cashfree
    }
  }, [data]);

  const viewVendor = () => {
    history.push('Vendor', { id: cartState.vendorOnCart.id });
  };

  return (
    <DefaultLayout>
      <Title
        name="Cart"
        navigateBack={() => (!loading || !loadingAsGuest ? history.goBack() : null)}
      />
      {isCartEmpty ? (
        <CartDishes
          dishes={cartState.dishesOnCart}
          onCheckout={checkPaymentModes}
          setTotalAmount={setTotalAmount}
          viewVendor={viewVendor}
          loading={loading || loadingAsGuest}
        />
      ) : (
        <EmptyCart goToVendors={goToVendors} />
      )}
      <PaymentOptionModal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        goToPayment={goToPayment}
        totalAmount={totalAmount}
        vendorId={cartState.vendorOnCart.id}
        spotId={Cookies.get('spotId')}
      />
      <ErrorModal code={error} onClose={() => setError('')} />
    </DefaultLayout>
  );
};

export default Cart;
