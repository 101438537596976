// @ts-nocheck
import { FC, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import OtpInput from 'react-otp-input';
import { Alert } from '@mui/material';

import LoadingButton from 'components/LoadingButton';

import theme from './theme.module.scss';

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  defaultFoodCourtId: string;
};

type LoginMutationResponse = {
  otpValidation: {
    user: User;
    tokenId: string;
  };
};

type LoginMutationVariables = {
  phoneNumber: string;
  otp: string;
};

const LOGIN_USER = gql`
  mutation otpValidation($phoneNumber: String!, $otp: String!) {
    otpValidation(phoneNumber: $phoneNumber, otp: $otp) {
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        defaultFoodCourtId
      }
      tokenId
    }
  }
`;

const EnterOTPFirstTime: FC<{
  phoneNumber: string;
  onSuccess: (tokenId: string, user: User) => void;
}> = ({ phoneNumber, onSuccess }) => {
  const [otp, setOtp] = useState('');
  const [validationError, setValidationError] = useState('');
  const [otpValidation, { loading, error }] = useMutation<
    LoginMutationResponse,
    LoginMutationVariables
  >(LOGIN_USER);

  function onSubmit() {
    setValidationError('');

    const numbers = /^\d{6}$/;

    if (!new RegExp(numbers).test(otp)) {
      setValidationError('Please enter a valid OTP. 6 digits long.');
      return;
    }

    otpValidation({
      variables: {
        phoneNumber,
        otp,
      },
    }).then((data) => {
      if (data && data.data && data.data.otpValidation)
        onSuccess(data.data.otpValidation.tokenId, data.data.otpValidation.user);
    });
  }

  return (
    <div className={theme.container}>
      <p className={theme.subTitle}>Enter One time Password</p>
      <p className={theme.description}>
        We've sent you an otp to the above mobile number. Kindly enter that here to sign in.
      </p>
      <OtpInput
        value={otp}
        onChange={(otp) => setOtp(otp)}
        numInputs={6}
        separator={<span>-</span>}
        inputStyle={theme.otpInput}
        focusStyle={theme.otpInputOnFocus}
        isDisabled={loading}
        isInputNum
        shouldAutoFocus
      />
      {validationError ? (
        <Alert severity="error" className={theme.errorAlert}>
          {validationError}
        </Alert>
      ) : null}
      {error ? (
        <Alert severity="error" className={theme.errorAlert}>
          There was an error processing your request. Kindly try again later
        </Alert>
      ) : null}
      <LoadingButton
        variant="contained"
        className={theme.submitButton}
        onClick={onSubmit}
        loading={loading}
      >
        Submit
      </LoadingButton>
    </div>
  );
};

export default EnterOTPFirstTime;
