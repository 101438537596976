import { FC } from 'react';
import { useHistory } from 'react-router';

import Title from 'components/Title';
import DefaultLayout from 'layouts/DefaultLayout';

import theme from './theme.module.scss';
import { Button } from '@mui/material';

const DeleteAccount: FC = () => {
  const history = useHistory();
  return (
    <DefaultLayout>
      <Title name="Delete Account" navigateBack={() => history.goBack()} />
      <div>
        <p>
          We're sorry to see you go. If you have any feedback on how we can improve, please let us
          know.
        </p>
        <p>
          If you'd like to delete your account, please use the button below and we'll take care of
          it for you in 7-8 business days. We'll miss you!
        </p>
        <a
          href="mailto:support@illude.co?Subject=Delete%20My%20Account"
          rel="noreferrer"
          target="_blank"
          className={theme.email}
        >
          <Button variant="contained" color="error">
            Delete Account
          </Button>
        </a>
      </div>
    </DefaultLayout>
  );
};

export default DeleteAccount;
